





























import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import Title from '@/components/common/page-layout/Title.vue'
import store from '@/store'
import { AuthorizationOutcome } from '@/services/organization-authorization-service'

@Component({
  components: {
    PageLayout,
    ContentArea,
    PrimaryButton,
    Title
  }
})

export default class Location extends Vue {
  private selected = false
  private isError= false
  private selectedItem = ''
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private userGeoLocation:any = {}

  async created ():Promise<void> {
    localStorage.setItem('testResult', '')
    this.userGeoLocation = await this.$store.dispatch('auth/getGeolocation')
    this.autoSelectionCountry()
    if (window.innerWidth < 767) {
      this.$root.$on('mobile-go-back', () => {
        // this.$router.push('signup/organization')
        this.goBack()
      })
    }

    localStorage.removeItem('selectedLocation')
  }

  private goBack (): void {
    this.$router.go(-1)
  }

  private handleAuthorizationResponse (): void {
    const authorizationOutcome = store.getters['auth/authorizationOutcome']
    if (authorizationOutcome !== AuthorizationOutcome.valid) {
      this.isError = true
      return
    }
    // router.push('demographic-welcome')
    localStorage.setItem('prevRoute', this.$route.path as string)
    this.isError = false
    this.$router.push({ name: 'log in' })
  }

  private selectedLocation (item:string):void {
    this.selectedItem = item
    this.selected = true
    localStorage.setItem('selectedLocation', item)
  }

  async autoSelectionCountry (): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const allLoc = this.$t('location.names') as any
    const userLoc = await this.$store.dispatch('auth/getGeolocation')
    const selectedLoc = this.selectedLocation
    var selected = false
    if (userLoc.length === 0) {
      selectedLoc('Other')
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      allLoc.map(function (value:any) {
        if (userLoc.countryCode === value.value && !selected) {
          selectedLoc(value.text)
          selected = true
        } else if (userLoc.region === value.value && !selected) {
          selected = true
          selectedLoc(value.text)
        } else if (value.value === 'Other' && !selected) {
          selected = true
          selectedLoc(value.text)
        }
      })
    }
  }

  private submit (): void {
    store.dispatch('auth/authorize').then(this.handleAuthorizationResponse)
  }
}
