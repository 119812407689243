









































import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import Title from '@/components/common/page-layout/Title.vue'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let els: any = []
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let ete: any = []
@Component({
  components: {
    PageLayout,
    ContentArea,
    PrimaryButton,
    Title
  }
})

export default class PrePaidCode extends Vue {
  private selected = false
  private isError= false
  private selectedItem = ''
  private code = ''
  private value = ''
  private flatValue = ''

  private modelCode: Array<string> = []

  async created ():Promise<void> {
    if (window.innerWidth < 767) {
      this.$root.$on('mobile-go-back', () => {
        this.$router.go(-1)
      })
    }
    localStorage.removeItem('selectedLocation')
  }

  mounted (): void {
    els = document.querySelectorAll('.input-field')
    ete = document.querySelectorAll('.primary-button')

    if (window.innerWidth < 767) {
      this.$root.$on('mobile-go-back', () => {
        this.goBack()
      })
    }
  }

  private goBack (): void {
    this.$router.go(-1)
  }

  private updateCode (): void {
    this.flatValue = this.modelCode.join('')
  }

  private updateVal (): void {
    var prevState = this.$store.getters['walkthrough/userInfo']
    this.$store.dispatch('walkthrough/setUserInfo', prevState)
  }

  private submit (): void {
    localStorage.setItem('prevRoute', this.$route.path as string)
    this.isError = false
    this.$router.push({ name: 'log in' })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onPaste (evt: any): void {
    var text = evt.clipboardData.getData('text').replace(/(\r\n|\n|\r)/gm, '').trim()
    setTimeout(() => {
      this.modelCode = text.split('-')
      if (text.length < 5) {
        if (this.modelCode[0].length === 4) {
          els[1].focus()
        } else {
          els[0].focus()
        }
      }
      if (text.length < 10) {
        if (this.modelCode[1].length === 4) {
          els[2].focus()
        } else {
          els[1].focus()
        }
      }
      if (text.length < 15) {
        if (this.modelCode[2].length === 4) {
          els[3].focus()
        } else {
          els[2].focus()
        }
      }
      if (text.length > 15) {
        ete[0].focus()
      }
    }, 1)
  }

  private setFocus (index: number): void {
    if (index === 1 && (this.flatValue.length > 0 && this.flatValue.length < 5)) {
      if (index < els.length && this.modelCode[0].length === 4) {
        els[1].focus()
      } else {
        els[0].focus()
      }
    } else if (index === 2 && (this.flatValue.length > 3 && this.flatValue.length < 9)) {
      if (index < els.length && this.modelCode[1].length === 4) {
        els[2].focus()
      } else if (index < els.length && this.modelCode[1].length === 0) {
        els[0].focus()
      } else {
        els[1].focus()
      }
    } else if (index === 3 && (this.flatValue.length > 7 && this.flatValue.length < 13)) {
      if (index < els.length && this.modelCode[2].length === 4) {
        els[3].focus()
      } else if (index < els.length && this.modelCode[2].length === 0) {
        els[1].focus()
      } else {
        els[2].focus()
      }
    } else if (index === 4 && (this.flatValue.length > 11)) {
      if (this.modelCode[3].length === 0) {
        els[2].focus()
      } else {
        els[3].focus()
      }
    }
  }
}
